import { useFormContext } from 'react-hook-form';

import { GenericFormInputHiddenFieldRendering, Fields } from '@sitecore/types/GenericFormInputHiddenField';

import { GenericFormFC } from '../../util';

const NAME_FALLBACK = 'inputFormHiddenField';

function isGenericFormHiddenInputField(field: Fields) {
  return field?.fieldName !== undefined || field?.value !== undefined;
}

const GenericFormInputHiddenField: GenericFormFC<GenericFormInputHiddenFieldRendering> = ({ fields }) => {
  const { register } = useFormContext();

  if (!isGenericFormHiddenInputField(fields)) {
    return null;
  }

  const name = fields?.fieldName?.value || NAME_FALLBACK;
  const value = fields?.value?.value || '';

  return <input value={value} type="hidden" {...register(name)} />;
};

export default GenericFormInputHiddenField;
